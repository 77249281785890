(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/components/slider/assets/javascripts/slider.js') >= 0) return;  svs.modules.push('/components/components/slider/assets/javascripts/slider.js');

var svs = svs || {};


(function(svs) {
  'use strict';

  svs.components = svs.components || {};

  svs.components.Slider = function(selector, options) {
    var sliderContainer = $(selector);
    var sliderContainerWidth = 0;
    var sliderWrapper = sliderContainer.find('.js-slider-wrapper');
    var body = $('body');
    var sliderWrapperWidth = 0;
    var slides;
    var numberOfSlides = 0;
    var sliderCalculatedCenterOffsets;
    var sliderCalculatedWiths;
    var transformSpeed = 200;
    var currentVelocity = 0;
    var currentPosition = 0;
    var minScrollPosition = 0;
    var oldSlideIndex = 0;
    var currentSlideIndex = -1;
    var controlSlider;
    var isSliderScrollable = true;
    var hasFiredScrolledStart = false;
    var startEvent;
    var moveEvent;
    var endEvent;
    var clickEvent;
    var clickPreventEvent;
    var scrollEvent;
    var pageFlipper;
    var pageFlipperVisible;
    var pageFlipped;
    var self;
    var scrollTimeoutIndex;

    options = options || {};

    function init() {
      resize(options.startIndex || 0);
      initEvents();
      self.trigger('init', {
        index: currentSlideIndex,
        el: slides[currentSlideIndex]
      });
    }

    function removeContainerEvents() {
      if (sliderContainer) {
        sliderContainer.off('mousedown touchstart', startEvent);
        sliderContainer.off('click', '.js-slider-slide', clickEvent);
        sliderWrapper.off('scroll', scrollEvent);
        sliderContainer[0].removeEventListener(
          'click',
          clickPreventEvent,
          true
        );
      }
    }

    function initEvents() {
      var delta;
      var isSwiping = false;
      var start;
      var touchStartTime;
      var triggeredBySwipe = false;

      removeContainerEvents();

      if (options.scrollSnap) {
        options.slideOnDrag = false;
      }

      options.slideOnDrag =
        options.slideOnDrag === undefined ? true : options.slideOnDrag;

      if (options.slideOnDrag) {
        startEvent = function(e) {
          triggeredBySwipe = false;
          hasFiredScrolledStart = false;

          if (!isSliderScrollable || $(e.target).hasClass('rsNoDrag')) {
            return;
          }

          var coordinates = getCoordinates(e);

          touchStartTime = Date.now();

          isSwiping = true;

          delta = {
            x: 0,
            y: 0
          };

          start = {
            x: coordinates.x,
            y: coordinates.y
          };
        };

        moveEvent = function(e) {
          if (isSwiping) {
            var coordinates = getCoordinates(e);

            delta = {
              x: coordinates.x - start.x,
              y: coordinates.y - start.y
            };

            currentPosition = calculateWidth(currentSlideIndex) + delta.x;

            moveWrapper(currentPosition, 0);

            if (delta.x > 10 || delta.x < -10) {
              e.preventDefault();
            } else if (delta.y > 5 || delta.y < -5) {
              isSwiping = false;
              currentPosition = calculateWidth(currentSlideIndex);

              moveWrapper(currentPosition);
            }

            if (isSwiping && !hasFiredScrolledStart) {
              hasFiredScrolledStart = true;
              self.trigger('scrollstart', {
                index: currentSlideIndex,
                el: slides[currentSlideIndex]
              });
            }
          }
        };

        endEvent = function(e) {
          if (isSwiping) {
            isSwiping = false;

            var isSwipe = false;
            var touchEndTime = Date.now();
            var timeDiff = touchEndTime - touchStartTime;
            var velocity = Math.abs(delta.x / timeDiff);

            if (options.snapSize && Math.abs(delta.x) > 0) {
              var index = Math.round(Math.abs(currentPosition) / options.snapSize);

              if (index === currentSlideIndex) {
                scrollToTab();
              } else {
                scrollTo(index);
              }

              isSwipe = true;
            } else if (velocity > 0.5) {
              currentVelocity = velocity;

              if (delta.x > 0) {
                isSwipe = true;
                scrollTo(currentSlideIndex - 1);
              } else if (delta.x < 0) {
                isSwipe = true;
                scrollTo(currentSlideIndex + 1);
              }
            } else if (delta.x > sliderContainer.width() / 2) {
              isSwipe = true;
              scrollTo(currentSlideIndex - 1);
            } else if (delta.x < sliderContainer.width() / 2 * -1) {
              isSwipe = true;
              scrollTo(currentSlideIndex + 1);
            } else {
              scrollToTab();
            }

            start = delta = {
              x: 0,
              y: 0
            };

            if (isSwipe) {
              triggeredBySwipe = true;
              hasFiredScrolledStart = false;
              e.preventDefault();
            }
          }
        };

        sliderContainer.on('mousedown touchstart', startEvent);
        body.on('mousemove touchmove', moveEvent);
        body.on('mouseup touchend', endEvent);
      }

      clickPreventEvent = function(e) {
        if (triggeredBySwipe) {
          e.preventDefault();
          e.stopPropagation();

          return false;
        }
      };

      clickEvent = function(e) {
        if (!triggeredBySwipe) {
          currentVelocity = null;

          var $currentTarget = $(e.currentTarget);
          var index = $currentTarget.data('index');
          var isDisabled = $currentTarget.data('is-disabled');
          if (isDisabled) {
            return;
          }

          if (options.noAutoFocus) {
            currentSlideIndex = index;
            setActiveTab(index);
          } else {
            scrollTo(parseInt(index, 10));
          }
          if (!options.followUrl) {
            e.preventDefault();
          }
        }
      };
      sliderContainer[0].addEventListener('click', clickPreventEvent, true);

      options.slideOnClick = options.slideOnClick || false;

      if (options.slideOnClick) {
        sliderContainer.on('click', '.js-slider-slide', clickEvent);
      }

      if (options.scrollSnap) {
        scrollEvent = function() {
          clearTimeout(scrollTimeoutIndex);

          scrollTimeoutIndex = setTimeout(function() {
            var index = Math.round((sliderWrapper[0].scrollLeft) / slides.eq(0).outerWidth(true));

            if (index >= 0) {
              scrollTo(index, true);
            }
          }, 100);
        };

        sliderWrapper.on('scroll', scrollEvent);
        $(window).on('resize', resize);
      }
    }

    function getCoordinates(event) {
      return {
        x:
          event.originalEvent && event.originalEvent.touches ?
            event.originalEvent.touches[0].pageX :
            event.clientX,
        y:
          event.originalEvent && event.originalEvent.touches ?
            event.originalEvent.touches[0].pageY :
            event.clientY
      };
    }

    function scrollTo(index, skipAnimation) {
      if (currentSlideIndex !== index) {
        currentSlideIndex = index;

        if (currentSlideIndex < 0) {
          currentSlideIndex = 0;
        }

        if (currentSlideIndex >= numberOfSlides) {
          currentSlideIndex = numberOfSlides - 1;
        }

        if (controlSlider) {
          controlSlider.scrollTo(currentSlideIndex, skipAnimation);
        }

        if (!hasFiredScrolledStart) {
          self.trigger('scrollstart', {
            index: currentSlideIndex,
            el: slides[currentSlideIndex]
          });
        }

        scrollToTab(skipAnimation);
      }
    }

    function scrollToTab(skipAnimation) {
      var endPosition; var hasChanged; var scrollDuration; var time;

      if (skipAnimation) {
        scrollDuration = 0;
      } else if (options.scrollSnap) {
        scrollDuration = 500;
      }

      endPosition = calculateWidth(currentSlideIndex);

      if (currentVelocity) {
        time = Math.abs((endPosition - currentPosition) / currentVelocity);

        scrollDuration = Math.round(time);
      }

      currentVelocity = 0;

      hasChanged = oldSlideIndex !== currentSlideIndex;

      oldSlideIndex = currentSlideIndex;

      setActiveTab(currentSlideIndex);

      if (hasChanged) {
        self.trigger('change:before', {
          index: oldSlideIndex,
          el: slides[oldSlideIndex]
        });
      }

      if (skipAnimation) {
        if (hasChanged) {
          self.trigger('change:after', {
            index: currentSlideIndex,
            el: slides[currentSlideIndex]
          });
        }
      } else {
        (function(index, el) {
          sliderWrapper.one(
            'webkitTransitionEnd otransitionend msTransitionEnd transitionend',
            function() {
              if (hasChanged) {
                self.trigger('change:after', { index: index, el: el });
              }
            }
          );
        })(currentSlideIndex, slides[currentSlideIndex]);
      }

      if (options.toggleSlide) {
        toggleSlide(currentSlideIndex);
      } else {
        moveWrapper(endPosition, scrollDuration);
      }

      setActiveTab(currentSlideIndex);

      if (hasChanged) {
        (function(index, el) {
          self.trigger('change', { index: index, el: el });
        })(currentSlideIndex, slides[currentSlideIndex]);
      }

      self.trigger('scrollend', {
        index: currentSlideIndex,
        el: slides[currentSlideIndex],
        position: Math.abs(endPosition)
      });
    }

    function moveWrapper(position, duration) {
      if (options.scrollSnap || options.solidNav || options.toggleSlide) {
        if (position >= 0) {
          if (duration > 0) {
            sliderWrapper[0].scroll({ left: position, behavior: 'smooth' });
          } else {
            sliderWrapper.scrollLeft(position);
          }
        }
      } else {
        if (duration === null || duration === undefined) {
          duration = transformSpeed;
        }

        if (position >= 0) {
          currentPosition = 0;
          self.trigger('reachedstart', {
            index: currentSlideIndex,
            el: slides[currentSlideIndex],
            position: currentPosition
          });
        } else if (position <= minScrollPosition) {
          currentPosition = minScrollPosition;
          self.trigger('reachedend', {
            index: currentSlideIndex,
            el: slides[currentSlideIndex],
            position: currentPosition
          });
        }

        if (!options.center) {
          if (options.bouncing) {
            if (position >= 0) {
              position = Math.round((1.0 - 1.0 / (position * 0.55 / sliderContainerWidth + 1.0)) *
                sliderContainerWidth);
            } else if (position <= minScrollPosition) {
              position =
                minScrollPosition -
                Math.round((1.0 -
                    1.0 /
                    ((minScrollPosition - position) * 0.55 /
                      sliderContainerWidth +
                      1.0)) *
                  sliderContainerWidth);
            }
          } else if (position >= 0) {
            position = 0;
          } else if (position <= minScrollPosition) {
            position = minScrollPosition;
          }
        }

        sliderWrapper.css({
          'transition-property': 'transform',
          'transition-duration': duration + 'ms',
          'transition-timing-function': 'cubic-bezier(0.25,0.1,0.25,1)',
          transform: 'matrix(1, 0, 0, 1, ' + Math.ceil(position) + ', 0)'
        });
      }
    }

    function calculateWidth(tabIndex) {
      var tabWidth = 0;

      if (options.scrollSnap || options.solidNav) {
        if (isNaN(tabIndex)) {
          return -1;
        }

        if (!sliderCalculatedWiths) {
          sliderCalculatedWiths = [];
          sliderCalculatedCenterOffsets = [];

          var sliderItemMargin = 30;
          var wrapperScrollLeft = sliderWrapper.scrollLeft();
          var wrapperOffsetLeft = sliderWrapper.offset().left;
          var windowWidthHalf = $(window).width() / 2;

          for (
            var sliderIndex = 0, sliderLength = slides.length;
            sliderIndex < sliderLength;
            sliderIndex++
          ) {
            if (options.fixedStartSlide && sliderIndex === 0) {
              sliderCalculatedWiths[sliderIndex] = -1;
            } else {
              var scrollPos =
                slides.eq(sliderIndex).position().left +
                wrapperScrollLeft -
                wrapperOffsetLeft -
                (windowWidthHalf - wrapperOffsetLeft) +
                slides.eq(sliderIndex).width() / 2 +
                sliderItemMargin;

              if (options.scrollSnap) {
                tabWidth = slides.eq(sliderIndex).outerWidth(true);

                scrollPos = slides[sliderIndex].offsetLeft;

                sliderCalculatedWiths[sliderIndex] =
                    scrollPos > 0 ? scrollPos : 0;

                if (tabWidth < sliderContainerWidth && sliderContainerWidth / 2 - (tabWidth / 2) > 0) {
                  sliderCalculatedCenterOffsets.push(Math.round(sliderContainerWidth / 2 - (tabWidth / 2)));
                } else {
                  sliderCalculatedCenterOffsets.push(0);
                }
              }
            }
          }
        }

        var scrollLeft = sliderCalculatedWiths[tabIndex];

        if (options.scrollSnap && options.center) {
          scrollLeft -= sliderCalculatedCenterOffsets[tabIndex];
        }

        return scrollLeft;
      }
      var totalWidth = 0;

      if (!sliderCalculatedWiths) {
        sliderCalculatedWiths = [];
        sliderCalculatedCenterOffsets = [];

        for (var i = 0, length = slides.length; i < length; i++) {
          tabWidth = slides.eq(i).outerWidth(true);

          sliderCalculatedWiths.push(totalWidth);

          totalWidth -= tabWidth;

          if (options.snapSize && totalWidth < minScrollPosition) {
            totalWidth = minScrollPosition;
          }

          if (tabWidth < sliderContainerWidth) {
            sliderCalculatedCenterOffsets.push(sliderContainerWidth / 2 - tabWidth / 2);
          } else {
            sliderCalculatedCenterOffsets.push(0);
          }
        }
      }

      totalWidth = sliderCalculatedWiths[tabIndex];

      if (options.center) {
        totalWidth += sliderCalculatedCenterOffsets[tabIndex];
      }

      return totalWidth;
    }

    function updatePageFlipper() {
      if (sliderWrapperWidth > sliderContainerWidth) {
        if (!pageFlipper) {
          pageFlipper = sliderContainer.find('.page-flipper');
          pageFlipper.find('.icon').on('click', function() {
            var scrollDuration =
              (sliderWrapperWidth - sliderContainerWidth) / 1;
            moveWrapper(
              calculateWidth(pageFlipped ? 0 : numberOfSlides - 1),
              scrollDuration
            );
            pageFlipped = !pageFlipped;
            pageFlipper.toggleClass('hide-flipper');
          });
        }
        if (!pageFlipperVisible) {
          pageFlipper.removeClass('hide-flipper');
          sliderContainer.find('.page-flipped').addClass('hide-flipper');
          pageFlipperVisible = true;
        }
      } else if (pageFlipperVisible) {
        pageFlipper.addClass('hide-flipper');
        pageFlipperVisible = false;
        pageFlipped = false;
        options.noAutoFocus && sliderWrapper.css('transform', '');
      }
    }

    function resize(index) {
      if (!sliderContainer) {
        return;
      }

      if (isNaN(index)) {
        index = undefined;
      }

      sliderCalculatedWiths = null;
      sliderCalculatedCenterOffsets = null;
      slides = sliderContainer.find('> .js-slider-wrapper > .js-slider-slide, > .js-slider-slide');
      numberOfSlides = slides.length;

      sliderContainerWidth = sliderContainer.outerWidth();
      sliderWrapperWidth = 0;

      slides.each(function(index) {
        sliderWrapperWidth += $(this).outerWidth(true);
        $(this).data('index', index);
      });

      minScrollPosition = (sliderWrapperWidth - sliderContainerWidth) * -1;

      if (options.snapSize && sliderContainerWidth % options.snapSize > 0) {
        minScrollPosition -= sliderContainerWidth % options.snapSize;
      }

      if (options.toggleSlide) {
        sliderWrapper.addClass('slider-wrapper-toggle');
      } else if (options.solidNav) {
        sliderWrapper.css({ width: '100%', 'text-align': 'center' }); 
      } else if (options.scrollSnap) {
        sliderWrapper.addClass('slider-wrapper-snap');

        if (options.center) {
          sliderWrapper.addClass('slider-wrapper-snap-center');
        }

        if (options.centerAllItems) {
          sliderWrapper.addClass('slider-wrapper-snap-center-all');
        }
      } else {
        sliderWrapper.css({ width: Math.ceil(sliderWrapperWidth) + 10 }); 
      }

      if (index !== undefined) {
        currentSlideIndex = index;
      }

      if (!options.center && sliderContainerWidth >= sliderWrapperWidth) {
        isSliderScrollable = false;
        currentSlideIndex = index || 0;
      } else {
        isSliderScrollable = true;
      }

      if (options.pageFlipper) {
        updatePageFlipper();
      }

      if (!options.noAutoFocus) {
        scrollToTab(true);
      }
    }

    function remove(index) {
      if (index <= currentSlideIndex) {
        currentSlideIndex--;
      }

      slides.eq(index).remove();

      resize();
    }

    function setActiveTab(index) {
      slides
        .removeClass('slider-slide-active')
        .eq(index)
        .addClass('slider-slide-active');
    }

    function toggleSlide(index) {
      slides
        .addClass('hide')
        .eq(index)
        .removeClass('hide');
    }

    function setControl(slider) {
      controlSlider = slider;
    }

    function setSnapSize(snapSize) {
      options.snapSize = snapSize;
      resize();
    }

    function getCurrentSlide() {
      return slides[currentSlideIndex];
    }

    function getCurrentSlideIndex() {
      return currentSlideIndex;
    }

    function getSlides() {
      return slides;
    }

    function destroy() {
      $(window).off('resize', resize);

      removeContainerEvents();

      if (moveEvent) {
        body.off('mousemove touchmove', moveEvent);
        moveEvent = null;
      }

      if (endEvent) {
        body.off('mouseup touchend', endEvent);
        endEvent = null;
      }

      sliderContainer = null;
      sliderCalculatedWiths = null;
      sliderCalculatedCenterOffsets = null;
    }

    self = new svs.core.Events({
      getCurrentSlide: getCurrentSlide,
      getCurrentSlideIndex: getCurrentSlideIndex,
      scrollTo: scrollTo,
      setControl: setControl,
      setSnapSize: setSnapSize,
      getSlides: getSlides,
      resize: resize,
      remove: remove,
      destroy: destroy
    });

    init();

    return self;
  };
})(svs);


 })(window);